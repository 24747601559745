import React from "react";
import MarkdownComponent from "../../MarkdownComponent";

function Sovitus() {
  return (
    <div>
      <h1>Sovittaminen</h1>
      <MarkdownComponent path="/RFkurssi/Sovittaminen.md" />
    </div>
  );
}
export default Sovitus;
